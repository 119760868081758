
@import '~@angular/material/theming';
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@include mat-core();

@mixin define-css-classes($theme) {

	@include angular-material-theme($theme);

	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	
/*  // CSS THEME-DEPENDENT-STYLES ARE HERE:
*/  .theme-dependent-colors {
      background: mat-color($primary);
      color: mat-color($accent);
  }
}

$app-themes: (
    indigo-pink : (primary-base: $mat-indigo, accent-base: $mat-pink),
    deeppurple-amber: (primary-base: $mat-deep-purple, accent-base: $mat-amber),
    pink-bluegrey : (primary-base: $mat-pink, accent-base: $mat-blue-gray),
    purple-green : (primary-base: $mat-purple, accent-base: $mat-green),
);

@each $css-class, $theme in $app-themes {
  $primary: if(map-has-key($theme, primary), map-get($theme, primary), mat-palette(map-get($theme, primary-base)));

  $accent: if(map-has-key($theme, accent), map-get($theme, accent), mat-palette(map-get($theme, accent-base)));

  $warn: if(map-has-key($theme, warn), map-get($theme, warn), mat-palette(
      if(map-has-key($theme, warn-base), map-get($theme, warn-base), $mat-red)
  ));

  .#{$css-class} {
    @include define-css-classes(mat-light-theme($primary, $accent, $warn));
  }

  .#{$css-class}-dark {
    @include define-css-classes(mat-dark-theme($primary, $accent, $warn));
  }

  .theme-primary.#{$css-class} {
    background-color: mat-color($primary);
  }

  .theme-accent.#{$css-class} {
    background-color: mat-color($accent);
  }

  .theme-warn.#{$css-class} {
    background-color: mat-color($warn);
  }
}

/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
 @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
 a{
  text-decoration: none;
 }
 
.body { margin: 0;
  background: #edf1f7;
  behavior: smooth;
  scroll-behavior: smooth;
}
  .text-white{
  color: white;
}
.font-weight-100{
  font-weight: 100;
}
.font-weight-200{
  font-weight: 200;
}
.font-weight-300{
  font-weight: 300;
}
.font-weight-400{
  font-weight: 400;
}
.font-weight-500{
  font-weight: 500;
}
.font-weight-600{
  font-weight: 600;
}
.cursor-pointer{
  cursor: pointer;
}
.w-100{
  width: 100%;
}
.height{
  height: 100%;
}
h1,h2,h3,h4,h5,h6,p,a,li,ul,span{
  font-family: 'Roboto Condensed', sans-serif!important;
}

.Recent-img{
  max-height: 180px;
}
.Recent-image{
  max-height: 220px;
  width: auto;
  margin-right: 9px!important;
}

.bg-white{
  background: white;
}
.mat-expansion-panel-body {
  padding: 0!important;
}
mat-expansion-panel-body {
  padding: 0!important;
}

html, body { height: 100%; }
body { margin: 0 }
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  background: #f7f7f7b9!important;
}
// .order-button{
//   border: .5px solid #d4474d;
// }
.modal-content {
  position: relative;
  display: flex;
  border-radius: 0px;
  flex-direction: column;
  margin-top: 100px !important;
  max-height: 530px;
  overflow-x: auto;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  z-index: 111111;
  box-shadow: 0 0 1px lightgrey;
  border: 1px solid #ffffff;
  outline: 0;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  position: sticky;
  background: #fff;
  align-items: flex-start;
  z-index: 111111;
  top: 0;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}
.mat-snack-bar-container {
  color: #fff!important;
  font-size: 32px;
  background: black!important;
  border-radius: 0;
  text-transform: capitalize;
  box-sizing: border-box;
  display: block;
  max-width: auto;
  min-width: auto;
  padding: 16px 16px;
  letter-spacing: 1px;
  text-align: center!important;
  min-height: 60px;
  transform-origin: center;
}


.ag-theme-alpine .ag-icon {
    font-family: "agGridAlpine"!important;
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

mat-tab-label {
  height: auto!important;
  // padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1.6;
  width: auto!important;
  text-align: center;
 
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  top: -15px!important;
  overflow: auto;
}
.mat-tab-label {
  height: 35px !important;
  padding: 0 5px !important;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1.6;
  // border: 1px solid;
  width: 178px !important;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}

.mat-table {
  width: 100%;
overflow-y: hidden;
max-height: 600px!important;
margin-bottom: 2px;
}
.mat-table:hover{
  overflow: auto !important;
}
mat-header-row {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
   background-color: transparent!important; 
}
.mat-raised-button{
  outline: none;
  border-radius: 1px !important;
}
.border-bottom-last{
  border-bottom: 1px solid #9c1c8b;
  }
  .product-border-heading{
      border-width: 80%;
     font-size: 20px;
      font-weight: 500;
  }

 
  .mat-ripple { display: none; }
  .purple-green .mat-tab-group.mat-primary .mat-ink-bar, .purple-green .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: transparent;
}
.mat-tab-body-content {
  height: 100%;
  overflow: hidden!important;
}

.purple-green .mat-stepper-horizontal, .purple-green .mat-stepper-vertical {
  // background-color: #f7f7f7;
  background-color: transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-step-header .mat-step-icon-state-edit {
  /* background-color: #673ab7; */
  color: #fff;
  background: chocolate;
}
.purple-green .mat-step-header .mat-step-icon-selected, .purple-green .mat-step-header .mat-step-icon-state-done, .purple-green .mat-step-header .mat-step-icon-state-edit {
  background-color:#962151!important;
  color: white;
}
.mat-mini-fab.mat-primary {
  background-color:#962151!important;
}
.bg-primary {
  background-color: #962151!important;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*{
  scrollbar-width: thin;
  scrollbar-color: lightgrey;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color:lightgray ;
  border-radius: 1px;
  border: 1px solid lightgrey;
}


.dropdown-menu {
  top: 100%;
  z-index: 1000;
    left: inherit;
  display: none;
  float: left;
  min-width: 10rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}
gallery-image {
  width: 78%;
  display: flex;
  height: 107%;
  justify-content: center;
  align-items: center;
}

.image-size{
  height: 300px;
  width: inherit;
  
  /* } */
  }

.mat-tab-label:focus:not(.mat-tab-disabled) {
  opacity: 1;
  // background: #cac9c9;
  border-bottom: 3px solid #ae3663;
}
.active1{
  background: brown;
  }
  .mat-progress-bar-buffer {
    background: #E4E8EB!important;
}


.mat-radio-outer-circle {
  box-sizing: border-box;
  height: 20px;
  left: 0;
  border:2px solid #6c0753;
  position: absolute;
  top: 0;
  transition: border-color ease 280ms;
  width: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: linear-gradient(45deg, #6c0753, #d4474d);
}

ng5-slider ng5-slider-pointer {
  cursor: pointer;
  width: 16px!important;
  height: 16px!important;
  /* position: relative; */
  top: -7px!important;
  background-color: #9c1c8b!important;
  z-index: 3;
  border-radius: 50%!important;
}
.ng5-slider .ng5-slider-pointer {
  cursor: pointer!important;
  width: 16px!important;
  height: 16px!important;
  /* position: relative; */
  top: -7px!important;
  background-color: #9c1c8b!important;
  z-index: 3;
  border-radius: 50%!important;
}
.ng5-slider:focus{
  outline: none!important;
  border: none!important;

}
.ng5-slider-pointer{
  outline: none!important;
  border: none!important;
}
.ng5-slider.animate .ng5-slider-bubble {
  font-size: 11px!important;
  font-weight: 600!important;
  -webkit-transition: all linear .3s;
  transition: all linear .3s;
}

*:focus{
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.border-bottom-last{
  border-bottom: 1px solid #9c1c8b;
  }
 

#footer{
margin-top: 4%;
padding-top: 20px;
padding-left: 15%;
padding-right: 10%;
padding-bottom: 2%;
color: white;
background-image: url("/assets/img/-banner.jpeg");
background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover;

}

.navbar-light .navbar-nav .nav-link {
 color: #9c1c8b; 
 font-weight: 600;
list-style: none;
display: inline-block;
}
.vr-view-button{
  background: linear-gradient( 
87deg
 , #da5a33, #f9cc29)
}
.vr-view-button:focus{
  outline: none;
}

mat-cell{
  font-size: 12px!important;
  font-weight:500!important;
}
.mat-cell{
  font-size: 12px!important;
  font-weight:500!important;
}
.mat-header-cell {
  background: #f7f7f7;
  /* width: 78%!important; */
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 13px!important;
  font-weight: 600!important;
  text-transform: capitalize;
}
mat-header-cell {
  background: #f7f7f7;
  /* width: 78%!important; */
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 13px!important;
  font-weight: 600!important;
  text-transform: capitalize;
}

// for ecom end*/
.mat-icon, mat-icon {
  z-index: 1!important;
}
.mat-tab-header {
  top: 0;
  display: flex;
  overflow: hidden;
  position: sticky!important;
  z-index: 11;
  flex-shrink: 0;
  background: #fafafa;
}
.fa-rupee-sign{
  font-weight: 600!important;
  font-size: 12px!important;
}


.mat-tab-label-active{
  /* background-color: red; */
  border-bottom: 4px solid brown;
  opacity: 1 !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.85em 0 0.25em 0!important;
  font-size: 14px!important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 1px 1px 1px 1px!important;
  padding: .0em .3em 0 .3em!important;
  background: white!important;
}
.mat-select-trigger {
  height: 1em;
}
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after, .mat-input-element:disabled {
  color: black;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1111!important;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

legend{
  font-family: 'Roboto Condensed', sans-serif;
}
$disc-color: red;
$card-heading : 12px;
$card-discount-color: $disc-color;
$font100:100;
$font200:200;
$font300:300;
$font400:400;
$font500:500;
$font600:600;
$font700:700;
$disctag-bg: #ce434d;
$disctag-color: #fff;
.card-heading{
  font-weight: $font500;
  font-size: $card-heading;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.4em 0 0.7em 0;
  font-size: 12px;
}
.discount-tag{
  font-size: $card-heading;
  font-weight: $font600;
  background: $disctag-bg;
  padding: 2px 13px 3px;
  color: $disctag-color;
  letter-spacing: 1px;
  position: absolute;
  top: 0px;
  z-index: 111;
}


// ecom or all 
.product-border-heading{
  border-width: 80%;
  font-weight: 600;
}


.tagline{
background:  #d4474d!important; 
border: 1px solid;
font-weight: 600;
color: white;
line-height: revert;
height: 29px;
letter-spacing: 2px;
width: 100%;
overflow: hidden;
}

.footer-heading-border{
border-bottom: 1.5px solid #6c0753;
}
.footer-center-link{
font-size: 14px!important;   
}
.footer-center-link a:hover{
color: #6c0753;
font-weight: 500;
padding-left: 10px;
transition: .5s ease-in-out;
}
.footer-heading{
font-size: 16px;
font-weight: 500;
letter-spacing: 1px;
}

.default-btn {
border-radius: 10px;
display: inline-block;
border: .75px solid #6c0753;
text-transform: uppercase;
font-weight: 500;

}
.buynow-button{
padding: 2px 9px 2px 9px;
border-radius: 4px;
font-size: 14px;
justify-content: center;
}

.move-to-top{
  margin: 0 auto!important;
  font-size: 12px;
  position: fixed;
  z-index: 111111;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  top: 81px;
  background: #fff;
  left: 45%;
  box-shadow: 0 0 9px grey;
}



// end   


// for emall 

